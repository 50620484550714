import {
  format as formatFns,
  endOfMonth as endOfMonthFns,
  addMonths,
  addDays,
  subDays,
  addBusinessDays as addBusinessDaysFns,
  differenceInDays,
  startOfWeek as startOfWeekFns,
  endOfWeek as endOfWeekFns,
} from 'date-fns';

type DayOfWeek =
  | 'sunday'
  | 'monday'
  | 'tuesday'
  | 'wednesday'
  | 'thursday'
  | 'friday'
  | 'saturday';

export class TheDay {
  private readonly dateFormat: Date;

  constructor(
    readonly day: number,
    readonly month: number,
    readonly year: number,
  ) {
    if (day < 1 || day > 31) {
      throw new Error(`Invalid day: ${day}`);
    }

    if (month < 1 || month > 12) {
      throw new Error(`Invalid month: ${month}`);
    }

    if (year < 1900 || year > 2200) {
      throw new Error(`Invalid year: ${year}`);
    }

    this.dateFormat = new Date(this.year, this.month - 1, this.day);

    if (!this.isValidDate()) {
      throw new Error(`Invalid date: ${this.year}-${this.month}-${this.day}`);
    }
  }

  static now(): TheDay {
    const date = new Date();
    return new TheDay(date.getDate(), date.getMonth() + 1, date.getFullYear());
  }

  static isThisMonth(date: TheDay): boolean {
    const today = TheDay.now();
    return today.year === date.year && today.month === date.month;
  }

  static tryParse(date: string): TheDay | null {
    try {
      return TheDay.parse(date);
    } catch (error) {
      return null;
    }
  }

  static parse(date: string): TheDay {
    if (!date) {
      return TheDay.now();
    }

    const presplitDate = date.split('T')[0];
    const [year, month, day] = presplitDate
      .split('-')
      .map((part) => parseInt(part));

    return new TheDay(day, month, year);
  }

  static from(date: Date): TheDay {
    if (!date) {
      return TheDay.now();
    }

    return new TheDay(date.getDate(), date.getMonth() + 1, date.getFullYear());
  }

  static fromTimestamp(timestamp: number): TheDay {
    const date = new Date(timestamp);
    return TheDay.from(date);
  }

  static areEqual(a: TheDay, b: TheDay): boolean {
    return a?.day === b?.day && a?.month === b?.month && a?.year === b?.year;
  }

  getWeekdayName(): DayOfWeek {
    const day = this.dateFormat.getDay();
    switch (day) {
      case 0:
        return 'sunday';
      case 1:
        return 'monday';
      case 2:
        return 'tuesday';
      case 3:
        return 'wednesday';
      case 4:
        return 'thursday';
      case 5:
        return 'friday';
      case 6:
        return 'saturday';
      default:
        throw new Error('Invalid day');
    }
  }

  toString(): string {
    const formattedDay = this.day.toString().padStart(2, '0');
    const formattedMonth = this.month.toString().padStart(2, '0');
    const stringDate = `${this.year}-${formattedMonth}-${formattedDay}`;

    const regex = /^\d{4}-\d{2}-\d{2}$/;
    const isValid = regex.test(stringDate);
    if (!isValid) {
      throw new Error(`Invalid date format: ${stringDate}`);
    }

    return stringDate;
  }

  isBeforeToday(): boolean {
    const today = TheDay.now();
    return (
      this.year < today.year ||
      (this.year === today.year &&
        (this.month < today.month ||
          (this.month === today.month && this.day < today.day)))
    );
  }

  isToday(): boolean {
    const today = TheDay.now();
    return this.isEqual(today);
  }

  format(format: string): string {
    return formatFns(this.dateFormat, format);
  }

  startOfMonth(): TheDay {
    return new TheDay(1, this.month, this.year);
  }

  endOfMonth(): TheDay {
    const end = endOfMonthFns(this.dateFormat);
    return new TheDay(end.getDate(), this.month, this.year);
  }

  startOfWeek(): TheDay {
    const start = startOfWeekFns(this.dateFormat, { weekStartsOn: 1 });
    return new TheDay(start.getDate(), this.month, this.year);
  }

  endOfWeek(): TheDay {
    const end = endOfWeekFns(this.dateFormat, { weekStartsOn: 1 });
    return new TheDay(end.getDate(), this.month, this.year);
  }

  addBusinessDays(days: number): TheDay {
    const newDate = addBusinessDaysFns(this.dateFormat, days);
    return TheDay.from(newDate);
  }

  isEqual(other: TheDay): boolean {
    if (!other) {
      return false;
    }

    return (
      this.day === other.day &&
      this.month === other.month &&
      this.year === other.year
    );
  }

  isBefore(other: TheDay): boolean {
    if (!other) {
      return false;
    }

    if (this.year < other.year) {
      return true;
    }

    if (this.year > other.year) {
      return false;
    }

    if (this.month < other.month) {
      return true;
    }

    if (this.month > other.month) {
      return false;
    }

    return this.day < other.day;
  }

  compare(other: TheDay): number {
    if (!other) {
      return 1;
    }

    if (this.isEqual(other)) {
      return 0;
    }

    return this.isBefore(other) ? -1 : 1;
  }

  subtractDays(days: number): TheDay {
    return TheDay.from(subDays(this.dateFormat, days));
  }

  addDays(days: number): TheDay {
    return TheDay.from(addDays(this.dateFormat, days));
  }

  addMonths(days: number): TheDay {
    return TheDay.from(addMonths(this.dateFormat, days));
  }

  toDate(): Date {
    return this.dateFormat;
  }

  daysUntil(other: TheDay): number {
    if (this.isEqual(other)) {
      return 0;
    }

    return differenceInDays(other.dateFormat, this.dateFormat);
  }

  private isValidDate(): boolean {
    return (
      this.dateFormat.getDate() === this.day &&
      this.dateFormat.getMonth() === this.month - 1 &&
      this.dateFormat.getFullYear() === this.year
    );
  }
}
