"use client";

import { createContext, ReactNode, useContext, useState } from "react";
import { Resource } from "~/types/Resource";

export type Selection = {
  workspaceName: string;
  workspaceId: string;
  resource: Resource;
};

type CalendarBooker = {
  start: (selection: Selection) => void;
  reset: () => void;
  selectionData: Selection | null;
  isActive: boolean;
};

const CalendarBookerContext = createContext<CalendarBooker>({
  start: () => {},
  reset: () => {},
  selectionData: null,
  isActive: false,
});

CalendarBookerContext.displayName = "CalendarBookerContext";

export const CalendarBookerProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [selected, setSelected] = useState<Selection | null>(null);

  return (
    <CalendarBookerContext.Provider
      value={{
        start: (selection) => setSelected(selection),
        isActive: !!selected,
        selectionData: selected,
        reset: () => setSelected(null),
      }}
    >
      {children}
    </CalendarBookerContext.Provider>
  );
};

export const useCalendarBooker = () => useContext(CalendarBookerContext);
