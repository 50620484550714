"use client";

import { exists } from "~/utils/exists";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useMe } from "~/hooks/useMe";
import { useToast } from "~/hooks/useToast";
import { useCommonDict } from "~/hooks/useTranslations";
import { UserAccountType } from "~/lib/gql/me/get-me.gql";
import { Intercom } from "@intercom/messenger-js-sdk";

declare global {
  interface Window {
    Intercom: any;
  }
}

interface UserData {
  email?: string;
  user_id?: string;
  name?: string;
}

interface SupportChat {
  openChat(): void;
  unreadCount: number;
}

const SupportChatContext = createContext<SupportChat>({} as SupportChat);

export const SupportChatProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { me } = useMe();
  const { t } = useCommonDict();
  const toast = useToast();

  const [isChatTriggered, setChatTriggered] = useState<boolean>(false);
  const [isScriptLoaded, setScriptLoaded] = useState<boolean>(false);
  const [isChatInitialized, setChatInitialized] = useState<boolean>(false);
  const [unreadMessagesCount, setUnreadMessagesCount] = useState<number>(0);

  const isProductionEnv = process.env.NEXT_PUBLIC_ENV === "prod";

  const initChat = useCallback(() => {
    if (!me) {
      return;
    }

    try {
      const userData = getUserData(me);

      Intercom({
        app_id: isProductionEnv ? "qffkqntn" : "ecgwy1c2",
        hide_default_launcher: true,
        region: "eu",
        session_duration: 30,
        action_color: "#007AFF",
        ...userData,
      });

      window.Intercom("onUnreadCountChange", function (unreadCount: number) {
        setUnreadMessagesCount(unreadCount);
      });

      setScriptLoaded(true);
      setChatInitialized(true);

      window.Intercom("show");
    } catch (error) {
      console.error("Failed to initialize Intercom:", error);
      toast.error(t("fpErrorTitle"), { description: t("fpErrorDesc") });
    }
  }, [me, isProductionEnv, t, toast]);

  useEffect(() => {
    if (isChatInitialized && me) {
      const userData = getUserData(me);
      window.Intercom("update", userData);
    }
  }, [me, isChatInitialized]);

  const openChat = () => {
    if (!isChatTriggered) {
      setChatTriggered(true);
      initChat();
      return;
    }

    if (!isChatInitialized) {
      initChat();
      return;
    }

    window.Intercom("show");
  };

  useEffect(() => {
    return () => {
      if (window.Intercom) {
        window.Intercom("shutdown");
      }
    };
  }, []);

  return (
    <SupportChatContext.Provider
      value={{
        openChat,
        unreadCount: unreadMessagesCount,
      }}
    >
      {children}
    </SupportChatContext.Provider>
  );
};

export function useSupportChat(): SupportChat {
  const context = useContext(SupportChatContext);
  return context;
}

const getUserData = (me?: UserAccountType): UserData => {
  const { firstName, lastName } = me?.profile ?? {};

  const userData = {
    email: me?.email ?? undefined,
    user_id: me?.sub ?? undefined,
    name: [firstName, lastName].filter(exists).join(" ").trim() || undefined,
  };

  userData.toString = function () {
    return JSON.stringify(this, null, 2);
  };

  return userData;
};
