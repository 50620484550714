import { useGate, useConfig } from "statsig-react";

export type GateName =
  | "datadog-browser-logging-perm"
  | "share-booking-perm"
  | "credit-card"
  | "subscribable-resources"
  | "scania-employees"
  | "social-feature"
  | "profile-picture-perm"
  | "customer-io"
  | "customer-io-push-notifications"
  | "calendar-integrations-perm"
  | "ios-outlook-integration-temp"
  | "flutter-location-temp"
  | "image-optimization"
  | "teams-feature-temp"
  | "nextjs-migration"
  | "booking-overview-temp"
  | "quick-book-in-saved-temp";

export const useFeatureGate = (gateName: GateName) => {
  return useGate(gateName);
};

export type DynamicConfigName = "service-log-level" | "guest-datadog-config";

export const useDynamicConfig = (configName: DynamicConfigName) => {
  return useConfig(configName);
};
